<template>
  <div class="max-w-screen-xl mx-auto">
    <div class="my-5 gap-y-5">
      <div
        :class="[
          'flex flex-col sm:flex-row w-full h-full mt-8 gap-12 pb-5',
          { 'justify-center items-center': !isExternal },
        ]"
      >
        <div
          v-if="customerId || isExternal"
          class="rounded-lg border border-neutral-50 shadow-bb-shadow py-4 mb-auto"
        >
          <div class="flex flex-col gap-3 text-center px-4 pt-5 pb-6 w-64 max-w-64">
            <p class="h5 font-bold">Total Score</p>
            <audit-donut :percentage="summary.score * 0.01"></audit-donut>
            <p class="h5">{{ scoreMessage.title }}</p>
            <p class="p3">{{ scoreMessage.description }}</p>
          </div>
        </div>
        <div class="w-full">
          <div class="flex flex-wrap w-full justify-evenly sm:justify-evenly gap-4 sm:gap-1">
            <div
              v-for="(issue, severity) in formattedIssues"
              :key="severity"
              class="flex flex-col gap-3 flex-grow max-w-60"
              @click="handleIssueClick(severity)"
            >
              <p class="h5 font-normal">{{ issue.title }}</p>
              <div
                class="shadow-bb-shadow rounded border border-transparent transform transition-all ease-in-out duration-300"
                tabindex="0"
                :class="[issue.boxClass, { 'w-50': isExternal }]"
              >
                <div :class="['border-l-8 rounded ', issue.borderColor]">
                  <div class="grid grid-cols-4 mx-5 py-3.5">
                    <div :class="['p-2 rounded-full mr-auto', issue.bgColor]">
                      <component
                        :is="issue.icon"
                        :class="issue.textColor"
                      />
                    </div>
                    <p class="h3 font-bold text-center">{{ issue.value ? issue.value : '0' }}</p>
                    <div
                      v-if="issue.trend > 0"
                      :class="['flex flex-row items-center', issue.trendColor]"
                    >
                      <p>{{ issue.trend }}</p>
                      <ic-menu-down :class="issue.trendClass" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="customerId || isExternal">
            <AuditWelcomeMessage class="mt-3 pt-4" />
          </div>
          <div
            v-else
            class="px-10"
          >
            <div class="mt-10 pb-2 border-b border-neutral-50">
              <p class="h5 font-bold">Category score</p>
            </div>
            <div class="mt-4 grid grid-cols-1 xl:grid-cols-2 gap-y-2 gap-x-10">
              <div
                v-for="(category, key) in formattedCategories"
                :key="key"
                class="flex items-center justify-between w-full gap-2 cursor-pointer duration-300 ease-linear hover:bg-neutral-0 rounded-lg py-1 px-2"
                @click="handleCategoryClick(key)"
              >
                <div class="flex items-center gap-2 w-full">
                  <component
                    :is="category.icon"
                    class="text-bb-disabled-gray flex-none"
                  />
                  <p class="h5 font-normal whitespace-no-wrap w-full">
                    {{ category.title }}
                  </p>
                </div>
                <div class="flex items-center w-full max-w-60">
                  <div class="flex items-center w-full">
                    <progress-bar
                      :value="category.percentage"
                      :progress-mark="101"
                      :show-percent-label="false"
                      use-solid-color
                      container-width="w-full"
                      container-height="8px"
                      class="flex-1 justify-end"
                    ></progress-bar>
                    <p class="font-bold h5 text-right w-13 whitespace-no-wrap">
                      {{ category.percentage !== null ? `${category.percentage}%` : '-%' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuditPerformanceBanner
        v-if="customerId || isExternal"
        :data="auditDetails?.last_30_days_performance_data"
      />
    </div>
  </div>
</template>

<script>
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcAlertTriangle from 'vue-material-design-icons/AlertOutline'
import AuditDonut from '@/components/audit-components/AuditDonut'
import IcMenuDown from 'vue-material-design-icons/MenuDown'
import IcLightbulbOutline from 'vue-material-design-icons/LightbulbOutline'
import IcCheckCircleOutline from 'vue-material-design-icons/CheckCircleOutline'
import IcKeyOutline from 'vue-material-design-icons/KeyOutline'
import IcMovieOutline from 'vue-material-design-icons/MovieOutline'
import IcStar from '@/components/icon/brightbid/ic-star'
import IcBullHorn from '@/components/icon/brightbid/ic-campaign.vue'
import ProgressBar from '@/components/shared/ProgressBar.vue'
import AuditWelcomeMessage from '@/components/audit-components/AuditWelcomeMessage'
import AuditPerformanceBanner from '@/components/audit-components/AuditPerformanceBanner'

export default {
  name: 'AuditSummary',
  components: {
    IcAlertCircle,
    IcAlertTriangle,
    AuditDonut,
    IcMenuDown,
    IcLightbulbOutline,
    IcCheckCircleOutline,
    IcKeyOutline,
    IcMovieOutline,
    IcStar,
    ProgressBar,
    IcBullHorn,
    AuditWelcomeMessage,
    AuditPerformanceBanner,
  },
  props: {
    summary: {
      type: Object,
      required: true,
    },
    customerId: {
      type: String,
      required: false,
      default: null,
    },
    isExternal: {
      type: Boolean,
      required: false,
    },
    auditDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    scoreMessage() {
      const score = this.summary.score
      if (score >= 90) {
        return {
          title: 'Excellent!',
          description:
            'Your Google account is in top shape! Keep up the great work to maintain this high score and enjoy seamless services.',
        }
      } else if (score >= 75) {
        return {
          title: 'Great Job!',
          description:
            "Your Google account is performing well. A few tweaks here and there, and you'll hit perfection in no time!",
        }
      } else if (score >= 50) {
        return {
          title: 'Room for improvement',
          description:
            "Your Google account is doing fine, but there's room for improvement. Review your audit and start optimizing for better performance.",
        }
      } else if (score >= 25) {
        return {
          title: 'Needs Improvement',
          description:
            'Your Google account could use some attention. Take a moment to review the audit and enhance your optimization for better performance.',
        }
      } else if (!score) {
        return {
          title: 'Score Generation Error',
          description: 'An error has occur when generating the account score. Please run audit again.',
        }
      } else {
        return {
          title: 'Critical Attention Required',
          description:
            'Your Google account is at risk. Immediate action is needed to improve your account health and increase your campaign results.',
        }
      }
    },
    formattedIssues() {
      const severityCounts = this.summary.check_count_by_severity || {
        urgent: { value: 0, previous_value: null },
        warning: { value: 0, previous_value: null },
        optimization: { value: 0, previous_value: null },
        passed: { value: 0, previous_value: null },
      }

      return {
        urgent: {
          title: 'Urgent Issues',
          icon: 'IcAlertTriangle',
          boxClass: 'focus:border-bb-error active:border-bb-error hover:scale-110 cursor-pointer',
          borderColor: 'border-bb-error',
          bgColor: 'bg-bb-red-50',
          textColor: 'text-bb-error',
          value: severityCounts.urgent.value,
          trend: this.getTrend(severityCounts.urgent.value, severityCounts.urgent.previous_value),
          trendColor: this.getTrendColor(severityCounts.urgent.value, severityCounts.urgent.previous_value, 'negative'),
          trendClass: this.getTrendClass(severityCounts.urgent.value, severityCounts.urgent.previous_value),
        },
        warning: {
          title: 'Warnings',
          icon: 'IcAlertCircle',
          boxClass: 'focus:border-bb-warning active:border-bb-warning hover:scale-110 cursor-pointer',
          borderColor: 'border-bb-warning',
          bgColor: 'bg-bb-yellow-50',
          textColor: 'text-bb-warning',
          value: severityCounts.warning.value,
          trend: this.getTrend(severityCounts.warning.value, severityCounts.warning.previous_value),
          trendColor: this.getTrendColor(
            severityCounts.warning.value,
            severityCounts.warning.previous_value,
            'negative',
          ),
          trendClass: this.getTrendClass(severityCounts.warning.value, severityCounts.warning.previous_value),
        },
        optimization: {
          title: 'Optimizations',
          icon: 'IcLightbulbOutline',
          boxClass: 'focus:border-bb-brand-purple active:border-bb-brand-purple hover:scale-110 cursor-pointer',
          borderColor: 'border-bb-brand-purple',
          bgColor: 'bg-bb-secondary-purple',
          textColor: 'text-bb-brand-purple',
          value: severityCounts.optimization.value,
          trend: this.getTrend(severityCounts.optimization.value, severityCounts.optimization.previous_value),
          trendColor: this.getTrendColor(
            severityCounts.optimization.value,
            severityCounts.optimization.previous_value,
            'negative',
          ),
          trendClass: this.getTrendClass(severityCounts.optimization.value, severityCounts.optimization.previous_value),
        },
        passed: {
          title: 'Passed checks',
          icon: 'IcCheckCircleOutline',
          boxClass: 'focus:border-success active:border-success hover:scale-110 cursor-pointer',
          borderColor: 'border-success',
          bgColor: 'bg-bb-success-bg',
          textColor: 'text-success',
          value: severityCounts.passed.value,
          trend: this.getTrend(severityCounts.passed.value, severityCounts.passed.previous_value),
          trendColor: this.getTrendColor(severityCounts.passed.value, severityCounts.passed.previous_value, 'positive'),
          trendClass: this.getTrendClass(severityCounts.passed.value, severityCounts.passed.previous_value),
        },
      }
    },
    formattedCategories() {
      return {
        ...(this.summary.score_by_category?.account && {
          account: {
            title: 'Account',
            icon: 'IcStar',
            percentage: this.summary.score_by_category?.account?.score ?? null,
          },
        }),
        ...(this.summary.score_by_category?.ads && {
          ads: {
            title: 'Ads & Assets',
            icon: 'IcMovieOutline',
            percentage: this.summary.score_by_category?.ads?.score ?? null,
          },
        }),
        ...(this.summary.score_by_category?.campaign && {
          campaign: {
            title: 'Campaign',
            icon: 'IcBullHorn',
            percentage: this.summary.score_by_category?.campaign?.score ?? null,
          },
        }),
        ...(this.summary.score_by_category?.keywords && {
          keywords: {
            title: 'Keywords',
            icon: 'IcKeyOutline',
            percentage: this.summary.score_by_category?.keywords?.score ?? null,
          },
        }),
      }
    },
  },
  methods: {
    getTrend(currentValue, previousValue) {
      if (previousValue === null) {
        return '' // Or return a specific string like 'No trend data'
      }
      const difference = currentValue - previousValue
      return difference > 0 ? `+${difference}` : `${difference}`
    },
    getTrendColor(currentValue, previousValue, type) {
      if (previousValue === null) {
        return '' // Default or neutral color if no previous value
      }
      const difference = currentValue - previousValue
      if (type === 'positive') {
        return difference > 0 ? 'text-success' : 'text-bb-error'
      }
      return difference > 0 ? 'text-bb-error' : 'text-success'
    },
    getTrendClass(currentValue, previousValue) {
      if (previousValue === null) {
        return '' // No rotation if no previous value
      }
      const difference = currentValue - previousValue
      return difference > 0 ? 'transform rotate-180' : ''
    },
    handleIssueClick(severity) {
      this.$emit('scrollToChecklist', severity)
    },
    handleCategoryClick(category) {
      this.$emit('scrollToCategory', category)
    },
  },
}
</script>
